::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #1e1e1e;
}

::-webkit-scrollbar-thumb {
  background: #85888d;
  border-radius: 25px;
}

::-webkit-scrollbar-thumb:hover {
  background: #51b8a0;
}
