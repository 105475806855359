@import url("https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,800&display=swap");

* {
  font-family: "Exo 2", sans-serif;
}

body {
  margin: 0;
  padding: 0;
}
